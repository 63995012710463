import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

function MarketingQuote(props) {
    const { image, quote, source } = props

    return (
        <div className="solution-row marketing-quote col-12">
            {image && image.src && (
                <GatsbyImage
                    image={image.src.childImageSharp.gatsbyImageData}
                    className="marketing-quote-image" />
            )}

            <div className="marketing-quote-copy">
                <p>{quote}</p>

                <p className="quote-source">{source}</p>
            </div>
        </div>
    );
}

export default MarketingQuote
