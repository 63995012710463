import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import ViewDemo from './ViewDemo'
import ViewVideo from './viewVideo'
import { buildCTALink, appendUtmsFromSearchToUrl, getSearchParams } from '../utilities'

function HeroRow(props) {
    const {
        hook,
        followUp,
        copy1,
        image,
        ctaButton,
        productHint,
        secondaryCta,
    } = props

    let ctaButtonEl = buildCTALink(ctaButton, productHint)

    const SecondaryComponent =
        secondaryCta && secondaryCta.type === 'video' ? ViewVideo : ViewDemo

    const secondaryCtaEl = secondaryCta ? (
        <SecondaryComponent url={secondaryCta.url} checked={productHint}>
            <button
                className={`button button-secondary ${productHint &&
                    productHint.toLowerCase()} __cta_tag`}
            >
                {secondaryCta.text}
            </button>
        </SecondaryComponent>
    ) : null

    let followUpEl = null
    if (followUp) {
        followUpEl = <h3>{followUp}</h3>
    }

    const imageEl = image ? (
        <div className="hero-illustration">
            <GatsbyImage image={image.src.childImageSharp.gatsbyImageData} />
        </div>
    ) : null;

    // gatsby pages are statically built, to add runtime params, use js on client side to append
    if (typeof document !== 'undefined' && ctaButton && ctaButton.text === "Purchase ad") {
        let links = document.querySelectorAll("a[href*='/launchpad/find_out/boost']");
        links.forEach((link) => {
            link.setAttribute('href', appendUtmsFromSearchToUrl(link.href, getSearchParams()));
        });
    }

    return (
        <div className="hero col-12">
            <div className="copy-content span-7-mx">
                <h1>{hook}</h1>
                <p className="copy1 span-6-mx">{copy1}</p>
                {followUpEl}
                <div className="call-to-action">
                    <div className="buttons">
                        {ctaButtonEl}
                        {secondaryCtaEl}
                    </div>
                </div>
            </div>
            {imageEl}
        </div>
    )
}

export default HeroRow
