import React from 'react'
import { graphql } from 'gatsby'

import '../styles/global.scss'

import Layout from '../components/Layout'
import SolutionNavigationRow from '../components/SolutionNavigationRow'
import HeroRow from '../components/HeroRow'
import MarketingRow from '../components/MarketingRow'
import MarketingRowReversed from '../components/MarketingRowReversed'
import MarketingQuote from '../components/MarketingQuote'

const components = {
    SolutionNavigationRow,
    HeroRow,
    MarketingRow,
    MarketingRowReversed,
    MarketingQuote,
}

export default function CustomerPage(props) {
    const { data, location } = props
    const { customersJson } = data
    const {
        name,
        rows,
        bodyClass,
        title,
        description,
        keywords,
    } = customersJson

    const htmlRows = rows.map((row, i) => {
        const RowComponent = components[row.component]
        return (
            <RowComponent
                key={i}
                type={name}
                location={location}
                {...row.props}
            />
        )
    })

    return (
        <Layout
            title={title}
            description={description}
            keywords={keywords}
            bodyClass={bodyClass}
        >
            {htmlRows}
        </Layout>
    )
}

export const pageQuery = graphql`query ($path: String!) {
  customersJson(path: {eq: $path}) {
    name
    bodyClass
    title
    description
    keywords
    rows {
      component
      props {
        productHint
        hook
        followUp
        copy1
        image {
          name
          src {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        quote
        source
        ctaButton {
          text
          href
          type
        }
        links {
          text
          href
        }
      }
    }
  }
}
`
